import type { ComponentType } from "react"

import toast from "react-hot-toast"
import { useEffect } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

import {
    handleLoginWithEmailLink,
    loginWithProvider,
    sendEmailToLoginWithEmailLink,
    signOut as signOutFunction,
} from "https://framer.com/m/handler-RUuV.js"

const useStore = createStore({
    isLoading: false,
    variant: "default",
})

export function withGoogle(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        const handleClick = async () => {
            setStore({ isLoading: true, variant: "locked" })

            if (store.variant !== "locked") {
                toast.promise(
                    loginWithProvider("google"),
                    {
                        loading: "Loading",
                        success: (user) => {
                            console.log({ user })
                            setStore({ isLoading: false, variant: "default" })
                            if (typeof window !== "undefined") {
                                window.location.href =
                                    "https://app.cursodeframer.com.br/cursos"
                            }

                            return `Bem vindo, ao Curso de framer`
                        },
                        error: (err) => {
                            setStore({ isLoading: false, variant: "default" })
                            return `Aconteceu algum erro: ${err.toString()}`
                        },
                    },
                    {
                        style: {
                            minWidth: "350px",
                        },
                        success: {
                            duration: 5000,
                            icon: "🔥",
                        },
                    }
                )
            }
        }

        return (
            <Component
                {...props}
                onClick={!store.isLoading ? handleClick : null}
                variant={store.variant}
            />
        )
    }
}

export function withGithub(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        const handleClick = async () => {
            setStore({ isLoading: true, variant: "locked" })

            toast.promise(
                loginWithProvider("github"),
                {
                    loading: "Loading",
                    success: (data) => {
                        setStore({ isLoading: false, variant: "default" })

                        return `Successfully saved ${data.user.displayName}`
                    },
                    error: (err) => {
                        setStore({ isLoading: false, variant: "default" })
                        return `This just happened: ${err.toString()}`
                    },
                },
                {
                    style: {
                        minWidth: "250px",
                    },
                    success: {
                        duration: 5000,
                        icon: "🔥",
                    },
                }
            )
        }

        return (
            <Component
                {...props}
                onClick={!store.isLoading ? handleClick : null}
                variant={store.variant}
            />
        )
    }
}

export function withEmailLink(Component): ComponentType {
    return (props) => {
        useEffect(() => {
            handleLoginWithEmailLink()
        }, [])

        return <Component {...props} />
    }
}

export function signOut(Component): ComponentType {
    return (props) => {
        const handleClick = async () => {
            await signOutFunction()

            window.location.href = "https://app.cursodeframer.com.br/login"
        }

        return <Component {...props} onClick={handleClick} />
    }
}
